<template>
  <b-row>
    <b-col cols="12">
      <overview-instagram :mainData="data.main_child.detail" />
    </b-col>

    <b-col cols="12" v-if="data.main_child.detail.brand_safety">
      <brand-safety :brand_safety="data.main_child.detail.brand_safety"/>
    </b-col>

    <b-col cols="12">
      <main-instagram :mainData="data.main_child.detail" />
    </b-col>

    <b-col cols="12" v-if="role !== 'espejo'">
      <profile-value :data="data.main_child.detail.estimation" />
    </b-col>

    <!-- Country City -->
    <b-col cols="12">
      <country-city-graph v-if="audienceData.gender_age.length > 0" :audienceData="audienceData"></country-city-graph>
    </b-col>

    <!-- audience graph -->
    <b-col cols="12">
      <audience-graph :audienceData="audienceData"/>
    </b-col>

    <!-- interest -->
    <b-col cols="12">
      <b-row class="px-1">
        <interest-instagram v-if="audienceData.interests.length > 0" :audienceData="audienceData"></interest-instagram>
      </b-row>
    </b-col>

    <!-- gender graph -->
    <b-col cols="12">
      <gender-age-graph v-if="checkAgeGender()" :graphData="audienceData" :type="true"></gender-age-graph>
    </b-col>

    <!-- load more  -->
    <b-col cols="12">
      <profile-crecimiento :audienceData="audienceData" />
    </b-col>

    <b-col cols="12" v-if="showEmbedInstagram && media.length > 0">
      <instagram-media :contents="media" :avatar="getAvatar()"/>
    </b-col>

  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import profile_services from '@/services/profile'
export default {
  components: {
    BRow,
    BCol,
    OverviewInstagram: () => import('./OverviewInstagram.vue'),
    MainInstagram: () => import('./MainInstagram.vue'),
    InterestInstagram: () => import('./InterestInstagram.vue'),
    ProfileValue: () => import('../ProfileValue.vue'),
    CountryCityGraph: () => import('./CountryCityGraph.vue'),
    AudienceGraph: () => import('../AudienceGraph.vue'),
    GenderAgeGraph: () => import('../GenderAgeGraph.vue'),
    ProfileCrecimiento: () => import('../ProfileCrecimiento.vue'),
    BrandSafety: () => import('./BrandSafety.vue'),
    InstagramMedia: () => import('./InstagramMedia.vue')
  },
  props: {
    data: {
      Type: Object,
      required: true
    },
    headerData: {
      Type: Object,
      required: true
    },
    showEmbedInstagram: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      role: 'brand',
      media: []
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
    this.getMedia()
  },
  computed: {
    audienceData() {
      const audienceData = []
      audienceData.followersTotal = this.data.calculate_followers.total
      audienceData.cities = this.data.main_child.detail.audience_geography.cities
      audienceData.countries = this.data.main_child.detail.audience_geography.countries
      audienceData.authenticity = this.data.main_child.detail.audience_authenticity
      audienceData.type = this.data.main_child.detail.audience_type
      audienceData.gender_age = this.data.main_child.detail.demography_by_age
      audienceData.ethnicity = this.data.main_child.detail.audience_ethnicity
      audienceData.languages = this.data.main_child.detail.audience_languages
      audienceData.reachability = this.data.main_child.detail.audience_reachability
      audienceData.interests = this.data.main_child.detail.audience_interests
      audienceData.growth = this.data.main_child.detail.growth
      audienceData.followers_chart = this.data.main_child.detail.followers_chart
      audienceData.following_chart = this.data.main_child.detail.following_chart
      audienceData.comments_rate = this.data.main_child.detail.comments_rate
      audienceData.comments_authenticity = this.data.main_child.detail.comments_authenticity
      audienceData.likes_comments_ratio = this.data.main_child.detail.likes_comments_ratio
      audienceData.estimation = this.data.main_child.detail.estimation
      return audienceData
    }
  },
  methods: {
    getAvatar() {
      return this.headerData.avatar
    },
    getMedia() {
      profile_services.getMediaInstagram(this.$route.params.alias, '5', 'most_recent')
        .then(response => {
          const contents = Object.values(response.results)
          const contents_format = contents.map(content => (
            {
              ...content,
              username: this.headerData.username,
              network: this.headerData.network,
              followers: 0,
              avatar: this.headerData.avatar,
              is_verified: this.headerData.is_verified
            }
          ))
          this.media = contents_format
        })
    },
    checkAgeGender() {
      if (this.audienceData.countries && this.audienceData.countries.length > 0) {
        return true
      } else if (this.audienceData.cities && this.audienceData.cities.length > 0) {
        return true
      }

      return false
    }
  }
}
</script>
